.readme__button {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: transparent;
    color: #000;
    max-width: 400px;
    padding: 10px;
    transition: .3s ease-in-out;
    user-select: none;
    -webkit-user-drag: none;
}

.readme__button p {
    margin: 0;
    padding: 10px 0;
}

.readme__button:hover {
    cursor: pointer;
}

.readme__div {
    padding: 0px;
    transition: .3s ease-in-out;
    width: 400px;
}

.readme__div_active {
    display: flex;
    flex-direction: column;
    background-color: #2e333a;
    color: #fff; 
    padding: 10px;
    width: 100%;
    max-width: 650px;
    border-radius: 0 0 10px 10px;
}

.readme__button_active {
    width: 100%;
    color: #fff;
    max-width: 650px;
    background-color: #2e333a;
    border-radius: 10px 10px 0 0;
}

.readme__text {
    font-size: 15px;
    text-align: left;
    display: block;
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease-in-out;
}

.readme__text_active {
    height: auto;
    padding: 10px;
}


.readme__button__block {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 650px;
    transition: .3s ease-in-out;
}