@font-face {
  font-family: "Circe";
  src: url('./fonts/Circe-Bold.woff2') format('woff2');
  font-weight: bold;
}

@font-face {
  font-family: "Circe";
  src: url('./fonts/Circe-Regular.woff2') format('woff2');
  font-weight: normal;
}

.circe-font {
  font-family: "Circe";
}

@font-face {
  font-family: 'Intro';
  src: url('./fonts/Intro-Regular.woff2') format('woff2');
  font-weight: normal;
}

@font-face {
  font-family: 'Intro';
  src: url('./fonts/Intro-Bold.woff2');
  font-weight: bold;
}

.intro-font {
  font-family: 'Intro';
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  justify-content: center;
  margin: 0;
  background-color: #ffffff;
  flex-direction: column;

}


.form {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: 400px;

}

input:focus {
  outline: none;
}

.button {
  border: none;
  background-color: #2e333a;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  height: 45px;
  margin: 40px 0 0;
  width: 100%;
  height: 80px;
  font-family: inherit;
}

.quote {
  color: #424242;
  margin: 0;
  padding-top: 35px;
  max-width: 450px;
  font-size: 14px;
}

.quote:last-of-type {
  padding-top: 0;
}

.button:hover {
  cursor: pointer;
}

label:first-child {
  margin-top: 15px;
}

.main {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: 15px;
}

.header {
  top: 0;
  width: 100%;
  padding: 25px 0 25px 0;
  background-color: #fff;
  box-shadow: 1px 1px 20px #2e333a3d;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.logo {
  width: 121px;
  left: 57px;

}

.language-select__div {
  width: 70px;
  height: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.language-select__item {
  font-family: 'Circe';
  padding: 5px;
  width: 35px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  -webkit-user-drag: none;
  transition: .3s ease;
}

.language-select__item:hover {
  cursor: pointer;
  opacity: .7;
}

.language-select__item_active {
  font-family: 'Circe';
  padding: 5px;
  background-color: #e6e6e6;
  text-decoration: underline;
  user-select: none;
  -webkit-user-drag: none;
  width: 35px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}