input {
    font-family: inherit;
    border: none;
    border-bottom: 1px solid rgb(46, 51, 58);
    font-size: 16px;
    height: 50px;
    margin: 5px 0 25px;
    text-align: left;
    width: 100%;
}

input::placeholder {
    color: #2e333a8a;
}

input:focus::placeholder {
    color: transparent;
}