select {
    background-color: #fff;
    border: 1px solid #2e333a;
    height: 50px;
    margin: 15px 0 35px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    font-family: inherit;
    font-size: 14px;
}
